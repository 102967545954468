<template>
  <div>
    <v-col>
      <v-row align="center" justify="center">
        <v-col v-for="i in [1, 2, 3]" :key="i + 'skeleton-loader'" cols="3">
          <v-skeleton-loader height="205" type="card" />
        </v-col>
      </v-row>
    </v-col>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SkipOrgAndApp',

  computed: {
    ...mapGetters(
        {
          organizations: 'organization/getOrganizations',
          products: 'product/getProducts',
          selectedProduct: 'product/getSelectedProduct'
        }
    )
  },

  methods: {
    ...mapActions({
      fetchAllOrganizations: 'organization/fetchAllOrganizations',
      updateSelectedOrganization: 'organization/updateSelectedOrganization',

      fetchAllProducts: 'product/fetchAllProducts',
      updateSelectedProduct: 'product/updateSelectedProduct'
    }),


    async redirect () {
      await this.fetchAllOrganizations()

      if (this.organizations.length === 1) {

        const selectedOrg = this.organizations[0]
        await this.updateSelectedOrganization(selectedOrg)

        await this.fetchAllProducts(selectedOrg.slug)

        if (this.products.length === 1) {

          const firstProduct = this.products[0]

          if (!firstProduct?._highestVersion) {

            await this.updateSelectedProduct(firstProduct)
            await this.$router.push('/project')

          } else {
            await this.$router.push('/product')
          }

        } else {
          await this.$router.push('/product')
        }

      } else {
        await this.$router.push('/organization')
      }
    }
  },

  async mounted () {
    await this.redirect()
  }
}
</script>



<style scoped lang="scss">

</style>
